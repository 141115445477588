<template>
  <div style="width: 100%; padding: 10px">
    <pro-report
      title="Document.SearchAuditTrail"
      :columns="columns"
      v-model="fullList"
      row-key="id"
      auto-search
      selectable
    >
      <template v-slot:selectedBody="{ row }">
        <audit-trail-record
          :process-id="row.processId"
          :tender-id="$route.params.id"
          :log-id="row.id"
        />
      </template>
    </pro-report>
  </div>
</template>

<script>
import ProReport from "@/components/PROSmart/Report/ProReport";
import AuditTrailRecord from "@/components/PROSmart/AuditTrail/AuditTrailRecord";

export default {
  name: "ViewAuditTrails",
  components: { AuditTrailRecord, ProReport },
  data() {
    return {
      selected: [],
      isShowItem: true,
      columns: [
        {
          name: "dataTime",
          i18NLabel: "Form.Field.DateTime",
          field: "dataTime",
          align: "left",
          sortable: true,
          style: "width: 200px",
          searchable: true,
          type: "datetime",
          convertTo: "datetime",
        },
        {
          name: "description",
          i18NLabel: "Form.Field.Document",
          field: "description",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "user",
          i18NLabel: "Form.Field.User",
          field: "user",
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "formStatus",
          i18NLabel: "Form Status",
          field: "formStatus",
          align: "left",
          searchable: true,
          type: "chip",
          list: [
            {
              label: this.getRes("Form.Field.Started"),
              value: "StartProcess",
            },
            {
              label: this.getRes("Form.Field.Approved"),
              value: "ApproveProcess",
            },
            {
              label: this.getRes("Form.Field.Rejected"),
              value: "RejectProcess",
            },
            {
              label: this.getRes("Form.Field.Error"),
              value: "Error",
              color: "error",
            },
          ],
        },
      ],
      data: [],
      logId: "",
      processId: "",
      description: "",
      search: {
        description: "",
      },
      fullList: [],
    };
  },
  methods: {
    multilingualUserName(eName, cName, tName) {
      let language = localStorage.getItem("Language");
      if (language !== undefined) {
        switch (language) {
          case "English":
            return eName;
          case "Chinese":
            return cName;
          case "TraditionalChinese":
            return tName;
          default:
            return eName;
        }
      }
    },
  },
  async mounted() {
    this.$proSmart.documentUi
      .getAuditTrailList(this, this.$route.params.id)
      .then((b) => {
        this.fullList = b.map((item) => {
          return {
            id: item.id,
            processId: item.processId,
            dataTime: new Date(item.logTime),
            description: item.formDisplayName,
            user: this.multilingualUserName(item.eName, item.cName, item.tName),
            formStatus: item.formStatus,
          };
        });
      });
  },
};
</script>
